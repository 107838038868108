import Cookies from "js-cookie";

export default {
    computed: {
        isFirstTime() {
            // Prevent onboarding.
            //
            // TODO: Delete when ecommerce gets more priority.
            return false;

            const alreadyVisitedGuestList = this.$store.state.cookies.alreadyVisitedGuestList;
            if (alreadyVisitedGuestList) {
                return false;
            }

            const queryStatus = this.$route.query.status;
            if (queryStatus) {
                console.log("`alreadyVisitedGuestList` cookie was not set, but URL had `status` query param.");

                // If there's a `?status=` in the URL, it means it's not their
                // first time.
                //
                // In that case, we set the cookie to ensure the next time this
                // computed property is evaluated, the cookie is already set to
                // `true`.
                this.setAlreadyVisitedGuestList(true);

                return false;
            }

            return true;
        },
    },
    methods: {
        setAlreadyVisitedGuestList(value = true) {
            Cookies.set("alreadyVisitedGuestList", value, { expires: 30 })
        },
    },
}
