<template>
  <div class="bb-onboarding" v-if="listAvailable && isFirstTime">
    <div class="bg-cercles bb-onboarding__container bb-onboarding__container--guest">
      <main class="og1 ogsteps-container">
        <div class="og1__container">
          <h1 class="og1__title">¡Bienvenid@ a la lista de nacimiento de {{ myList.ownerName }}</h1>
          <div class="og1__subtitle">
            {{ myList.ownerName }} ha hecho su lista en HelloBB, una lista de nacimiento digital en la que
            encontrarás cosas que se venden en distintas tiendas.
          </div>
          <section class="og1__msg">
            <div class="msg-big">
              <div class="msg-big__title">{{ myList.ownerName }} ha dejado un mensaje para ti:</div>
              <div class="msg-big__main">
                <div class="msg-big__picture">
                  <img
                    v-if="myList.photo === null"
                    src="../../assets/images/embarazada.jpg"
                    width="108"
                    height="108"
                    srcset="
                      ../../assets/images/embarazada-p-500.jpeg   500w,
                      ../../assets/images/embarazada-p-800.jpeg   800w,
                      ../../assets/images/embarazada-p-1080.jpeg 1080w,
                      ../../assets/images/embarazada.jpg         1314w
                    "
                    sizes="(max-width: 479px) 100vw, 200px"
                    alt
                  />
                  <img v-else :src="getImgUrl()" alt="HelloBB" />
                </div>
                <div class="msg-big__content">
                  <div class="msg-big__msg">
                    “{{ welcomeMessage }}”
                  </div>
                </div>
              </div>
            </div>
          </section>
          <aside class="og1__actions">
            <div class="nav-onboarding-guest">
              <div class="nav-onboarding-guest__button">
                <router-link :to="{ name: 'OnboardingGuestStep2', params: { id: myList.id } }" class="button button--primary">
                  Continuar
                </router-link>
              </div>
            </div>
          </aside>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
import CanGetGuestList from "../../Mixins/CanGetGuestList";
import GuestListFirstVisit from "../mixins/GuestListFirstVisit";

export default {
  name: "OnboardingGuestStep1",
  mixins: [
    CanGetGuestList,
    GuestListFirstVisit,
  ],
  data() {
    return {
      msg: "",
      ownerName: "",
    };
  },
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
    welcomeMessage() {
      if(this.myList.welcomeMessage) return this.myList.welcomeMessage;
      else return "¡Hola! Hemos creado esta lista con cosas que nos hacen falta para cuando llegue nuestro bebé. Ten en cuenta que si compras directamente en HelloBB, acumularemos dinero y no tendremos problemas con posibles devoluciones. ¡Muchas gracias!"
    },
  },
  methods: {
    getImgUrl() {
      if (this.myList.photo == "" || this.myList.photo == null) {
        return "#";
      }
     return process.env.URL_IMG_USER + this.myList.photo;
    },
    goToList() {
      this.$router.push({
        name: "guest",
        params: {
          id: this.myList.id,
          hash: "#products_starting",
        },
      });
    },
  },
  mounted() {
    if (!this.isFirstTime) {
      return this.goToList();
    }
  },
};
</script>

<style lang="scss" scoped></style>
