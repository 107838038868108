import {mapGetters} from "vuex";

export default {
  data() {
    return {
      listAvailable: false
    }
  },
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
  },
  methods: {
    async getList(server=false, id = null) {
      return this.$store.dispatch("getListGuest", { id: id ? id : this.$route.params.id, server});
    },
    async getListFromServer() {
      return await this.getList(true)
    },
  },
  async serverPrefetch() {
    return await this.getListFromServer();
  },
  async mounted() {
    if (!this.myList) {
      await this.getList()
    } else {
      if (!this.myList.server) {
        await this.getList()
      }
    }
    this.listAvailable = true
  }
}